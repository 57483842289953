<template>
    <main-layout>
        <v-row align="center">
            <v-col lg="4" sm="6" xs="12" class="mx-auto">
                <v-row justify="center" class="pb-4">
                    <v-progress-circular
                        indeterminate
                        :width="8"
                        color="primary"
                    ></v-progress-circular>
                </v-row>
                <v-row>
                    <p class="text-center text-h5">
                        {{ $t("home.waitForAccessWifi") }}
                    </p>
                </v-row>
            </v-col>
        </v-row>
    </main-layout>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import { mapActions, mapGetters } from "vuex";
import screen from "@/mixins/screen.js";

export default {
    name: "Home",
    components: {
        MainLayout,
    },
    mixins: [screen],

    watch: {
        isAccessWifi(newVal) {
            if (newVal === true) {
                this.$router.push("/marketing");
            } else if (newVal === false) {
                this.$router.replace("/error");
            }
        },
    },

    computed: {
        ...mapGetters({
            isAccessWifi: "store/isAccessWifi",
        }),
    },

    created() {
        this.sendUserLoginLog();
    },

    methods: {
        ...mapActions({
            sendUserLoginLog: "store/sendUserLoginLog",
        }),
    },
};
</script>
